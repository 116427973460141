jQuery(document).ready(function ($) {
  // Sonar Beacons
	$("#beacon_collab .sonar-beacon").click(function (blip) {
		var overlaycaption = $("#arb_collab_img .elementor-image img").attr("alt");
		$("#arb_collab_img .elementor-image").append('<div class="inside_overlay"></div>');
		$("#arb_collab_img .inside_overlay").append('<h3>' + overlaycaption + '</h3>');
		$("#arb_collab_img .inside_overlay").css({
			"visibility": "visible",
			"opacity": 1
		});
		$('#beacon_collab').fadeOut("fast");
	});

	$("#beacon_board .sonar-beacon").click(function (blip) {
		var overlaycaption = $("#arb_collab_img_b .elementor-image img").attr("alt");
		$("#arb_collab_img_b .elementor-image").append('<div class="inside_overlay"></div>');
		$("#arb_collab_img_b .inside_overlay").append('<h3>' + overlaycaption + '</h3>');
		$("#arb_collab_img_b .inside_overlay").css({
			"visibility": "visible",
			"opacity": 1
		});
		$('#beacon_board').fadeOut("fast");
	});

	$("#beacon_cafe .sonar-beacon").click(function (blip) {
		var overlaycaption = $("#arb_cafe_img .elementor-image img").attr("alt");
		$("#arb_cafe_img .elementor-image").append('<div class="inside_overlay"></div>');
		$("#arb_cafe_img .inside_overlay").append('<h3>' + overlaycaption + '</h3>');
		$("#arb_cafe_img .inside_overlay").css({
			"visibility": "visible",
			"opacity": 1
		});
		$('#beacon_cafe').fadeOut("fast");
	});

	$("#beacon_studio .sonar-beacon").click(function (blip) {
		var overlaycaption = $("#arb_fit_img .elementor-image img").attr("alt");
		$("#arb_fit_img .elementor-image").append('<div class="inside_overlay"></div>');
		$("#arb_fit_img .inside_overlay").append('<h3>' + overlaycaption + '</h3>');
		$("#arb_fit_img .inside_overlay").css({
			"visibility": "visible",
			"opacity": 1
		});
		$('#beacon_studio').fadeOut("fast");
	});

	$("#beacon_courts .sonar-beacon").click(function (blip) {
		var overlaycaption = $("#arb_fit_img_b .elementor-image img").attr("alt");
		$("#arb_fit_img_b .elementor-image").append('<div class="inside_overlay"></div>');
		$("#arb_fit_img_b .inside_overlay").append('<h3>' + overlaycaption + '</h3>');
		$("#arb_fit_img_b .inside_overlay").css({
			"visibility": "visible",
			"opacity": 1
		});
		$('#beacon_courts').fadeOut("fast");
	});

	$("#beacon_indoor .sonar-beacon").click(function (blip) {
		var overlaycaption = $("#arb_fit_img_c .elementor-image img").attr("alt");
		$("#arb_fit_img_c .elementor-image").append('<div class="inside_overlay"></div>');
		$("#arb_fit_img_c .inside_overlay").append('<h3>' + overlaycaption + '</h3>');
		$("#arb_fit_img_c .inside_overlay").css({
			"visibility": "visible",
			"opacity": 1
		});
		$('#beacon_indoor').fadeOut("fast");
	});

	//need to use on because element is appended w jquery
	$(document).on('click', '.inside_overlay', function () {
		$(".inside_overlay").remove();
		$(this).css({
			"visibility": "visible",
			"opacity": 0
		});
		$('#beacon_collab').fadeIn("fast");
		$('#beacon_board').fadeIn("fast");
		$('#beacon_cafe').fadeIn("fast");
		$('#beacon_studio').fadeIn("fast");
		$('#beacon_courts').fadeIn("fast");
		$('#beacon_indoor').fadeIn("fast");
	});

	//start vimeo video when play button is clicked
	$(document).on('click', '.arb-vid-bttn a', function () {		
		var iframe = document.querySelector('iframe.popupvideo');
		var player = new Vimeo.Player(iframe);
		player.play();
});
$(document).on('click', '.menu-item', function () {	
},function(){
		
	$(this).removeClass('sfHover');
});

	

});